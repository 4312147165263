import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {

  connect() {
    this.toggleTrauma(this.data.has("typeIncident") && this.data.get("typeIncident") === 'trauma')
    this.toggleNumberCard(this.data.has("isNumberCard") && this.data.get("isNumberCard") === 'true')
    this.toggleHospitalStatus(this.data.get("hospitalStatus"))
    this.toggleSeverity(this.data.get("typeSeverity"))
    this.toggleFormMed(this.data.get("typeFormMed"))
    this.toggleCovidTest(this.data.has("covidTest") && this.data.get("covidTest") === 'true')
    this.toggleDelivered(this.data.has("totalStatus") && this.data.get("totalStatus") === 'emergency_room')
    this.toggleOtherField(this.data.has("otherField") && this.data.get("otherField") === '1')
  }

  clicked(e) {
    this.toggleTrauma(e.currentTarget.value === 'trauma')
  }

  clickedSeverety(e){
    this.toggleSeverity(e.currentTarget.value)
  }

  clickedFormMed(e){
    this.toggleFormMed(e.currentTarget.value)
  }

  clickedSymptom(e){
    this.toggleSymptom(e.currentTarget.value)
  }

  clickedNumberCard(e){
    this.toggleNumberCard(e.currentTarget.value === 'true')
  }

  clickedHospitalStatus(e){
    this.toggleHospitalStatus(e.currentTarget.value)
  }

  clickedCovidTest(e){
    this.toggleCovidTest(e.currentTarget.value  === 'true')
  }

  clickedDelivered(e){
    this.toggleDelivered(e.currentTarget.value === 'emergency_room')
  }

  clickedOtherField(e){
    this.toggleOtherField(e.currentTarget.checked === true)
  }

  toggleTrauma(isTrauma) {
    if (isTrauma) {
      $('#trauma').show()
      $('#disease').hide()
      $('#disease input[type="radio"]:checked').prop('checked', false)
    } else {
      $('#disease').show()
      $('#trauma').hide()
      $('#trauma input[type="radio"]:checked').prop('checked', false)
    }
  }

  toggleSeverity(isSeverity) {
    if (isSeverity === 'easy' ) {
      $('#incident_form_med_label_emergency').prop('disabled', true)
      $('#incident_form_med_label_urgent').prop('disabled', false)
    } else if (isSeverity === 'heavy') {
      $('#incident_form_med_label_urgent').prop('disabled', true)
      $('#incident_form_med_label_emergency').prop('disabled', false)
    } else {
      $('#incident_form_med_label_emergency').prop('disabled', false)
      $('#incident_form_med_label_urgent').prop('disabled', false)
    }
  }

  toggleFormMed(isFormMed) {
    if (isFormMed === 'emergency') {
      $('#incident_severity_label_easy').prop('disabled', true)
      $('#incident_severity_label_heavy').prop('disabled', false)
    } else if (isFormMed === 'urgent') {
      $('#incident_severity_label_heavy').prop('disabled', true)
      $('#incident_severity_label_easy').prop('disabled', false)
    } else {
      $('#incident_severity_label_easy').prop('disabled', false)
      $('#incident_severity_label_heavy').prop('disabled', false)
    }
  }

  toggleHospitalStatus(hospitalStatus) {
    if (hospitalStatus === 'hospitalized') {
      $('#care_unit_label').show()
      $('#voc_diagnosis_code').show()
      $('#clarified_diagnosis').show()
    } else if (hospitalStatus === 'specified') {
      $('#care_unit_label').hide()
      $('#voc_diagnosis_code').hide()
      $('#clarified_diagnosis').hide()
      $('#incident_care_unit_label').val(null).trigger('change')
      $('#incident_voc_diagnosis_code_id').val(null).trigger('change')
      $('#incident_clarified_diagnosis_label').val(null).trigger('change')
    } else if (hospitalStatus === 'released'){
      $('#incident_care_unit_label').val(null).trigger('change')
      $('#care_unit_label').hide()
      $('#voc_diagnosis_code').show()
      $('#clarified_diagnosis').show()
    }
  }

  toggleNumberCard(isNumberCard) {
    if (isNumberCard) {
      $('#number_card').show()
      $('#voc_hospitals').show()
      $('#total_status').show()
    } else {
      $('#number_card').hide()
      $('#delivered').hide()
      $('#voc_hospitals').hide()
      $('#total_status').hide()
      $('input[id^="incident_total_status_"]:radio').hide().prop('checked', false)
      $('input[id^="incident_hospital_status_"]:radio').hide().prop('checked', false)
      $('#incident_sent_hospital_label').val(null).trigger('change')
      $('.select2-hidden-accessible').val(null).trigger('change')
    }
  }

  toggleSymptom(isSymptom) {
    $('input[id^="incident_voc_symptom_ids_"]:checkbox:disabled').prop('disabled', false)
    $("input[type=checkbox][value=" + isSymptom + "]").prop('disabled', true).prop('checked', false)
  }

  toggleCovidTest(isCovid){
    if (isCovid) {
      $('#covid_test_on').show()
    } else {
      $('#covid_test_on').hide()
      $('input[id^="incident_covid_test_conclusion_"]:radio').hide().prop('checked', false)
    }
  }

  toggleDelivered(isDelivered){
    if (isDelivered) {
      $('#delivered').show()
    } else {
      $('#delivered').hide()
      $('input[id^="incident_hospital_status_"]:radio').hide().prop('checked', false)
    }
  }

  toggleOtherField(isField){
    if (isField) {
      $('#other_filed').show()
      $('#voc_hospital').hide()
    } else {
      $('#incident_new_hospital_label').val(null).trigger('change')
      $('#other_filed').hide()
      $('#voc_hospital').show()
    }
  }
}
