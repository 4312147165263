import { Controller } from "stimulus"
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

Chart.plugins.unregister(ChartDataLabels)

export default class extends Controller {
  connect() {
    let ctx = this.element.getContext('2d')

    let labels = JSON.parse(this.data.get('labels')) || []
    let colors = JSON.parse(this.data.get('colors')) || []
    let data = JSON.parse(this.data.get('data')) || []
    let title = this.data.get('title')
    let datasetLabel = JSON.parse(this.data.get('datasetLabel'))

    if (data.length === 3) {
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            barPercentage: 0.2,
            label: datasetLabel[0],
            data: data[0],
            backgroundColor: colors[0]
          }, {
            barPercentage: 0.2,
            label: datasetLabel[1],
            data: data[1],
            backgroundColor: colors[1]
          }, {
            barPercentage: 0.2,
            label: datasetLabel[2],
            data: data[2],
            backgroundColor: colors[2]
          }]
        },
        options: {
          maintainAspectRatio: true,
          responsive: true,
          title: {
            display: true,
            text: title
          },
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true
            }]
          }
        }
      })
    } else {
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            barPercentage: 0.2,
            label: datasetLabel[0],
            data: data[0],
            backgroundColor: colors[0]
          }, {
            barPercentage: 0.2,
            label: datasetLabel[1],
            data: data[1],
            backgroundColor: colors[1]
          }]
        },
        options: {
          maintainAspectRatio: true,
          responsive: true,
          title: {
            display: true,
            text: title
          },
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true
            }]
          }
        }
      })
    }
  }

  disconnect() {
    this.chart.destroy()
  }
}
