import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {

  connect() {
    this.toggleEvent(this.data.has("isAdmin") && this.data.get("isAdmin") === 'admin')
  }

  clicked(e) {
    this.toggleEvent(e.currentTarget.value === '1')
  }

  toggleEvent(isAdmin) {
    if (isAdmin) {
      $('#event').hide()
      $('#user_event_ids').val(null).trigger('change')
    } else {
      $('#event').show()
    }
  }
}
