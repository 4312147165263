import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {

  connect() {
    this.toggleEvent(this.data.has("isEvent") && this.data.get("isEvent") === 'true')
  }

  clicked(e) {
    this.toggleEvent(e.currentTarget.value === 'true')
  }

  toggleEvent(isEvent) {
    if (isEvent) {
      $('#event').show()
    } else {
      $('#event').hide()
      $('.select2-hidden-accessible').val(null).trigger('change')
    }
  }
}
